const defaultTitle = document.title

function getTitleFromRoute(vm) {
  const { $route } = vm
  if (!$route) {
    return null
  }

  return $route.name || $route.path
}

function getTitleFromComponent (vm) {
  const { title } = vm.$options

  return title && typeof title === 'function'
    ? title.call(vm)
    : title
}

const titleFactories = [
  '', // Hack for the reduce method in getTitle
  getTitleFromComponent,
  getTitleFromRoute,
  vm => defaultTitle
]

function getTitle(vm) {
  // ASSUMPTION: titleFactories has a first item of an empty string and the rest are functions
  return titleFactories.reduce((acc, fn) => {
    if (acc && acc !== '') {
      return acc
    }
    return fn(vm)
  })
}

export default {
  computed: {
    pageTitle() {
      return getTitle(this)
    }
  },
  created () {
    document.title = this.pageTitle
  }
}
