<template>
  <b-navbar fixed="top" toggleable="lg" variant="dark" type="dark">
    <b-navbar-brand href="#">{{appMeta.thisApp.title}}</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>

      <b-navbar-nav class="ml-auto">
        <!--
        <AppNavigation :appMeta="appMeta"></AppNavigation>

        <b-nav-item-dropdown right>
          <template #button-content>
            <b-icon icon="person-circle"></b-icon>
          </template>
          <b-dropdown-item disabled>{{appMeta.version}}</b-dropdown-item>
        </b-nav-item-dropdown>
        -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
// import AppNavigation from './AppNavigation.vue'

export default {
  name: 'AppHeader',
  props: ['appMeta'],
  components: {
    // AppNavigation
  },
  computed: {
    routes() {
      return this.$router.options.routes
    }
  }
}
</script>
