import Vue from 'vue'

const globalComponents = [

]

function register(Vue, components) {
  if (!components) {
    return
  }

  Object.keys(components)
    .forEach(key => {
      const component = components[key]
      Vue.component(key, component)
    })
}

globalComponents.forEach(obj => register(Vue, obj))
