<template>
  <div id="app">
    <AppHeader :appMeta="appMeta"></AppHeader>

    <div class="ve-page-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AppHeader from './lib/enterprise/AppHeader.vue'

export default {
  name: 'App',
  components: {
    AppHeader
  },
  computed: {
    appMeta: () => {
      return {
        thisApp: {
          id: 'website',
          title: 'Merxala',
          ...(window.appMeta || {})
        },
        apps: {
          app1: {
            id: 'website',
            name: 'Merxala',
            icon: 'diamond-half',
            url: '/#',
            permission: 'website:access'
          }
        },
        user: {
          displayName: 'Test User',
          permissions: [
            'website:access'
          ]
        }
      }
    }
  }
}
</script>

<style>
.ve-page-content {
  margin-top: 56px;
  padding: 0.5em;
}
</style>
