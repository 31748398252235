import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import './plugins'
import './components'
import './mixins'
import routes from './routes'
import storeFactory from './store'
import VueAnalytics from 'vue-analytics'

export default function(App) {
  // webpack to replace
  // eslint-disable-next-line no-undef
  window.appMeta = APP_META

  Vue.config.productionTip = false

  const ga = process.env.GA_CODE
  if (ga) {
    Vue.use(VueAnalytics, {
      id: ga
    })
  }

  const store = new Vuex.Store(storeFactory())
  const router = new VueRouter({ routes })

  return new Vue({
    store,
    router,
    render: h => h(App)
  })
}
