<template>
  <b-jumbotron class="offering" bg-variant="light">
    <template #lead>
      <slot name="title"></slot>
    </template>
    <hr class="my-1">
    <div class="content">
      <slot name="content"></slot>
    </div>
  </b-jumbotron>
</template>

<script>

export default {
  name: 'Offering',
  props: []
}
</script>

<style>
  .offering.jumbotron {
    padding:        0.25rem;
    margin-bottom:  1.5rem;
  }

  .offering.jumbotron > .lead {
    text-align:     center;
    margin:         0.5rem 0.25rem;
  }

  .offering.jumbotron > div.content {
    margin:         0 0.5rem 0.5rem;
  }

  @media (min-width: 576px) {
    .offering.jumbotron {
      min-height:   18rem; /* this value is set based on the content in Default.vue */
    }
  }
</style>
