<template>
  <b-container>
    <b-row align-h="center">
      <b-col sm="9">
        <b-jumbotron
            class="splash"
            header="Merxala"
            lead="Technology for Tomorrow">

          <p>
            What does digital transformation mean for your business? Let us use our expertise
            and experience to leverage the right technologies to help with your digital transformation.
          </p>

          <b-button variant="primary" href="mailto:contact@merxala.com">Get in Touch</b-button>

        </b-jumbotron>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col sm="4">
        <Offering>
          <template #title>
            <img class="shopify" :src="`${publicPath}3rd-party/shopify/partners-logos/SVG/ShopifyPlus_Secondary.svg`"/>
          </template>
          <template #content>
            <p>
              We are a member of the <a href="https://www.shopify.com/partners" target="_new">Shopify Partner Program</a> and provide the following services:
            </p>
            <ul>
              <li>Store building &amp; migration</li>
              <li>Theme development &amp; customization</li>
              <li>App Development</li>
              <li>Custom storefronts</li>
            </ul>
          </template>
        </Offering>
      </b-col>
      <b-col sm="4">
        <ServiceDescriptor title="Analytics &amp; Data Visualization">
          <p>Launch your organization's analytics practice and build a single source of truth for your data.</p>
          <ul>
            <li>Data acquisition from internal &amp; external data sources</li>
            <li>Data visualization using Power BI</li>
          </ul>
        </ServiceDescriptor>
      </b-col>
      <b-col sm="4">
        <ServiceDescriptor title="Business Process Automation">
          <p>Leverage technology to streamline your business process.</p>
          <ul>
            <li>Increase productivity</li>
            <li>Improve responsiveness to your customers</li>
          </ul>
        </ServiceDescriptor>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col sm="4">
        <ServiceDescriptor title="System Integration">
          <p>Integrate 3rd party platforms and APIs with your back office systems.</p>
          <ul>
            <li>Enterprise: Microsoft Teams, Salesforce</li>
            <li>eCommerce: Shopify, Amazon Seller Central, WooCommerce</li>
            <li>Parcel Carriers: FedEx, UPS, USPS</li>
          </ul>
        </ServiceDescriptor>
      </b-col>
      <b-col sm="4">
        <ServiceDescriptor title="Cloud Infrastructure">
          <p>Build and deploy infrastructure assets in the cloud.</p>
          <ul>
            <li>Infrastructure as code</li>
            <li>CI/CD pipelines</li>
            <li>Kubernetes, Docker</li>
          </ul>
        </ServiceDescriptor>
      </b-col>
      <b-col sm="4">
        <ServiceDescriptor title="General IT &amp; Software Consulting">
          <p>Let us provide guidance with</p>
          <ul>
            <li>Agile transformation</li>
            <li>Cloud migration</li>
            <li>Build vs buy decisions</li>
            <li>Technology evaluations</li>
          </ul>
        </ServiceDescriptor>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ServiceDescriptor from './ServiceDescriptor.vue'
import Offering from './Offering.vue'

export default {
  name: 'Default',
  components: {
    ServiceDescriptor,
    Offering
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style>
  .splash.jumbotron {
    background-color: transparent;
    text-align: center;
  }

  .splash.jumbotron .display-3 {
    font-size: 2.5rem;
  }

  p.integrationType {
    margin-bottom: 0.25rem;
  }

  @media (min-width: 576px) {
    .splash.jumbotron {
      padding: 2rem;
    }
  }

  img.shopify {
    height: 1.75rem;
  }
</style>
